<template>
    <b-dropdown
        id="dropdown-form"
        :text="t('filters')"
        variant="secondary"
        ref="dropdown"
        class="mr-2"
        boundary="window"
        @show="formStateCatch"
        @hide="filterCatch"
    >
        <b-dropdown-form>
            <b-form-group
                v-if="!activeDoctorId && this.hasSurgeonsPermission()"
                :label="t('surgeon')"
                label-for="dropdown-form-surgeon"
                @submit.stop.prevent
            >
                <Selector
                    id="dropdown-form-surgeon"
                    :placeHolder="t('selectSurgeon')"
                    :options="surgeonsOptions"
                    :propertiesToSearch="['optionDisplayText']"
                    :required="false"
                    :state="isNil(filters.surgeon) ? null : true"
                    optionValueKey="doctorId"
                    optionDisplayKey="optionDisplayText"
                    optionDisabledKey="optionDisabled"
                    v-model="filters.surgeon"
                >
                    <template #option="{option}">
                        <div class="patient-filters-surgeon-selector-option">
                            <SearchedText
                                class="ml-1"
                                :disabled="option.optionDisabled"
                                :searchedItem="option"
                                searchedProperty="optionDisplayText"
                            ></SearchedText>
                        </div>
                    </template>
                    <template #no-options-found> No surgeons found </template>
                </Selector>
            </b-form-group>

            <b-form-group :label="t('createdBy')" label-for="dropdown-form-createdby">
                <b-form-input
                    id="dropdown-form-createdby"
                    :state="filters.createdBy ? true : null"
                    :placeholder="t('createdBy')"
                    v-model="filters.createdBy"
                ></b-form-input>
            </b-form-group>

            <b-form-group>
                <b-form-row>
                    <b-col>
                        <b-form-checkbox
                            v-model="filters.hasReserved"
                            class="no-wrap"
                            name="check-button"
                            :value="1"
                            :unchecked-value="null"
                            switch
                        >
                            {{ t('lensesReserved') }}
                        </b-form-checkbox>
                    </b-col>
                    <b-col>
                        <b-form-checkbox
                            v-model="filters.hasOrdered"
                            class="no-wrap"
                            name="check-button"
                            :value="1"
                            :unchecked-value="null"
                            switch
                        >
                            {{ t('lensesOrdered') }}
                        </b-form-checkbox>
                    </b-col>
                </b-form-row>
            </b-form-group>

            <b-form-group>
                <b-form-row>
                    <b-col>
                        <b-form-checkbox
                            v-model="filters.active"
                            class="no-wrap"
                            name="check-button"
                            :value="1"
                            :unchecked-value="null"
                            switch
                        >
                            {{ t('activeOnly') }}
                        </b-form-checkbox>
                    </b-col>
                    <b-col>
                        <b-form-checkbox
                            v-model="filters.priority"
                            class="no-wrap"
                            name="check-button"
                            :value="1"
                            :unchecked-value="null"
                            switch
                        >
                            {{ t('priorityOnly') }}
                        </b-form-checkbox>
                    </b-col>
                </b-form-row>
            </b-form-group>

            <b-form-row>
                <b-col>
                    <b-button variant="secondary" @click="cancel">{{ t('cancel') }}</b-button>
                </b-col>
                <b-col class="d-flex justify-content-end">
                    <b-button
                        class="mr-2"
                        variant="secondary"
                        :disabled="!canCleanFilters"
                        @click="clear"
                        >{{ t('clear') }}</b-button
                    >
                    <b-button variant="primary" :disabled="hasFormChanged" @click="apply">{{
                        t('apply')
                    }}</b-button>
                </b-col>
            </b-form-row>
        </b-dropdown-form>
    </b-dropdown>
</template>

<script>
import {mapGetters} from 'vuex';
import merge from 'lodash/merge';
import pick from 'lodash/pick';
import isNil from 'lodash/isNil';
import Selector from '@/components/Selector';
import SearchedText from '@/components/SearchedText.vue';
import {surgeonIdNameFormat} from '@/utilities/formatters';
import {PERMISSIONS, PERMISSIONS_VALUES} from '@/constants/permissions';
import {checkPermissions} from '@/utilities/permissions';

const defaultFilters = () => ({
    surgeon: null,
    hasReserved: null,
    hasOrdered: null,
    active: null,
    priority: null,
    createdBy: null,
});

export default {
    name: 'PatientFilters',
    components: {Selector, SearchedText},
    data() {
        return {
            filters: defaultFilters(),
            formState: JSON.stringify(defaultFilters()),
        };
    },

    computed: {
        ...mapGetters({
            doctors: 'doctors/list',
            activeDoctorId: 'user/activeDoctorId',
            permissions: 'permissions/permissions',
            currentUser: 'user/currentUser',
        }),
        surgeonsOptions() {
            if (!this.hasSurgeonsPermission()) {
                return [];
            }
            const options = this.doctors.map((s) => ({
                doctorId: s.doctorId,
                optionDisplayText: this.surgeonIdNameFormat(
                    s?.ocosDoctorId || 'N/A',
                    `${s?.firstName || 'N/A'} ${s?.lastName || 'N/A'}`,
                    s.active
                ),
                optionDisabled: false,
            }));

            options.unshift({
                doctorId: 0,
                optionDisplayText: this.t('noSurgeon'),
                optionDisabled: false,
            });

            return options;
        },
        canCleanFilters() {
            return Object.values(this.filters).some((x) => !isNil(x));
        },
        hasFormChanged() {
            return this.formState == JSON.stringify(this.filters);
        },
    },

    methods: {
        checkPermissions(permissionsToCheck) {
            return checkPermissions(
                permissionsToCheck,
                this.permissions,
                this.currentUser?.accessPermissions
            );
        },
        isNil,
        surgeonIdNameFormat,
        formStateCatch() {
            this.formState = JSON.stringify(this.filters);
        },
        filterCatch() {
            this.filters = JSON.parse(this.formState);
        },
        cancel() {
            this.$refs.dropdown.hide(true);
        },
        /**
         * Sets the surgeon filter
         *
         * If the active surgeon has been set then use it. Otherwise, keep
         * the current surgeon filter.
         */
        setSurgeonFilter() {
            this.filters = {
                ...this.filters,
                surgeon: this.activeDoctorId || this.filters.surgeon,
            };
        },
        clear() {
            this.filters = defaultFilters();
            this.setSurgeonFilter();
            this.apply();
            this.cancel();
        },
        apply() {
            this.$emit('change', this.filters);
            this.formStateCatch();
            this.cancel();
        },
        hasSurgeonsPermission() {
            return this.checkPermissions({
                [PERMISSIONS.SURGEONS]: [
                    PERMISSIONS_VALUES.READ_ONLY,
                    PERMISSIONS_VALUES.READ_WRITE,
                    PERMISSIONS_VALUES.LIMITED_READ_ONLY,
                ],
            });
        },
    },

    mounted() {
        const storedParams = localStorage.getItem('pl-query-params');
        if (this.hasSurgeonsPermission()) {
            this.$store.dispatch('doctors/fetchList', {
                currentPage: 1,
                perPage: 0,
            });
        }
        this.filters = merge(
            {},
            this.filters,
            pick(JSON.parse(storedParams), Object.keys(defaultFilters())),
            pick(this.$route.query, Object.keys(defaultFilters()))
        );
    },
};
</script>
<style></style>
